import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faCircleXmark,
  faShareNodes,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { NewsWidget } from '@/widgets/news-widget';
import LocalWidgetRouter from '@/widgets/widget-router/local-widget-router';
import { convertWidgetToReactPath } from '@/widgets/widget-router/convert-widget-to-react-path';
import { Button } from 'react-bootstrap';
import ClipboardButton from '@/components/shared/clipboard-button';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { useWidgetPermission } from '@/hooks/use-widget-permission';
import HasWidgetPermissions from '@/widgets/widget-permissions/has-widget-permissions';
import { WidgetUpgradeBadge } from '@/widgets/widget-permissions/widget-upgrade-badge';
import styles from './dashboard-widget-panel.module.css';
import useWidgetNotifications from '@/widgets/notifications/use-widget-notifications';

type Layout = { x: number; y: number; w: number; h: number };
export default function DashboardWidgetPanel({
  widget,
  editMode,
  onClose,
  onHeaderDoubleClick,
  onSave,
  highlight,
}: {
  widget: NewsWidget;
  editMode: Boolean;
  onClose: () => void;
  onHeaderDoubleClick: () => void;
  onSave: () => void;
  highlight: boolean;
}) {
  const [widgetPath, setWidgetPath] = useState<string>();

  const [hovering, setHovering] = useState<boolean>(false);
  const [title, setTitle] = useState<string | JSX.Element>();
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const myRef = useRef<HTMLDivElement | null>(null);

  const { notifications } = useWidgetNotifications();

  useEffect(() => {
    if (highlight) {
      myRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  }, [highlight]);

  useEffect(() => {
    setNotificationCount(notifications.filter(value => value.widgetIds.includes(widget.id)).length);
  }, [notifications]);

  const openPopup = (url: string, width = 600, height = 800) => {
    window.open(url, url, `width=${width},height=${height}`);
  };

  return (
    <div
      className={` h-100 `}
      style={{ userSelect: 'none' }}
      ref={myRef}
      onMouseEnter={event => setHovering(true)}
      onMouseOver={event => setHovering(true)}
      onMouseLeave={event => setHovering(false)}
    >
      <div
        className={`${widget.containerClass ? widget.containerClass() : 'card rounded-2 h-100'} `}
      >
        <WidgetContainerContext.Provider
          value={{
            setTitle: title1 => {
              widget.titleText = title1;
              setTitle(widget.headerPanel);
            },
            get widgetId(): string | undefined {
              return widget.id;
            },
          }}
        >
          <div
            className={`card-body h-100 d-flex flex-column  ${(highlight && styles.flash) || ''}`}
            style={{}}
          >
            <div className={'d-flex justify-content-between align-items-center ps-2'}>
              <strong
                onDoubleClick={onHeaderDoubleClick}
                style={{ fontFamily: 'Inter', fontWeight: 700 }}
                className={
                  'drag-handle flex-grow-1 d-flex align-items-center gap-1  text-truncate '
                }
              >
                {title || (widget.headerPanel as JSX.Element)}{' '}
                <sup>
                  <WidgetUpgradeBadge widget={widget} />
                </sup>
                {notificationCount > 0 && (
                  <small>
                    <span className={'badge bg-danger'}>{notificationCount}</span>
                  </small>
                )}
              </strong>
              <span
                onDrag={event => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                className={'flex-shrink-0'}
              >
                {!editMode && (
                  <>
                    <ClipboardButton text={window.location.href + `?widget=${widget.id}`}>
                      <button className={'btn btn-link '} onClick={event => {}}>
                        <FontAwesomeIcon icon={faShareNodes} />
                      </button>
                    </ClipboardButton>

                    <button
                      className={'btn btn-link '}
                      onClick={() => openPopup(convertWidgetToReactPath(widget.id, widgetPath))}
                    >
                      <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </button>
                  </>
                )}
                {editMode && (
                  <button className={'btn btn-link link-danger'} onClick={onClose}>
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </button>
                )}
              </span>
            </div>
            <div className={'overflow-hidden flex-shrink-1 h-100 position-relative '}>
              {editMode && (
                <div
                  className={
                    'position-absolute z-3 w-100 h-100 bg-body-secondary bg-opacity-75 d-flex text-center flex-column'
                  }
                >
                  <div className={'mt-auto mb-auto opacity-100 text-white'}>
                    <div className={'m-2 small'}>
                      Editing Dashboard. Grab the title bar above to move, and use handles to
                      resize. Save to continue.
                    </div>
                    <div>
                      {hovering && (
                        <Button
                          size={'sm'}
                          className={'opacity-100'}
                          onClick={onSave}
                          variant={'primary'}
                        >
                          Save Dashboard
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <HasWidgetPermissions widget={widget}>
                <LocalWidgetRouter widget={widget} onNavigate={setWidgetPath} />
              </HasWidgetPermissions>
            </div>
          </div>
        </WidgetContainerContext.Provider>
      </div>
    </div>
  );
}
