import React from 'react';
import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';
import ArticleViewPage from '@/pages/news/article-view-page';
import { articleApi } from '@/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTape, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import PinnedArticle from '@/components/pins/pinned-article';
import PinnedArticlePage from '@/pages/news/pinned-article-page';

export class PinnedArticleWidget implements NewsWidget {
  titleText: string = 'Pinned Article';
  constructor(private readonly articleId: string) {}

  get id(): string {
    return `/pinned/${this.articleId}`;
  }

  async hasPermission(permissions: string[]) {
    return true;
  }

  initialize(host: WidgetHost) {}
  get headerPanel(): JSX.Element {
    return (
      <>
        <span className={'w-100   position-relative'}>{this.titleText}</span>
        <span
          className={'position-fixed  w-100 text-center overflow-visible '}
          style={{
            top: -5,
            left: 0,
            zIndex: 1000000,
            pointerEvents: 'none',
            overflow: 'visible !important',
            overflowClipMargin: 'unset',
            overflowAnchor: 'unset',
            overflowBlock: 'visible',
          }}
        >
          <img src={'/tape.png'} width={'150px'} alt={'pin'} />
        </span>
      </>
    );
  }

  get shortTitle(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faThumbtack} />
      </>
    );
  }

  containerClass() {
    return 'note-blue sticky-note shadow-sm';
  }
  get routes(): RouteObject[] {
    return [
      {
        path: '',
        element: <PinnedArticlePage className={'note-blue'} />,
        loader: async ({}) => {
          try {
            const article = await articleApi.getArticle(this.articleId || '');
            const title = article.headline;

            return { article, title, loaded:true };
          } catch (e) {
            return e;
          }
        },
      },
    ];
  }

  get defaultRoute(): string {
    return '';
  }
}
