import {MyMNIPreferences} from '@/model/preferences/myMNIPreferences';
import axios from 'axios';


export enum PreferenceConstants {
  MY_MNI = 'myMNI'
}

async function overwritePreferences<T>(key:string, preferencesUpdate: T): Promise<T> {
  return (await axios.put(`/api/preferences/${encodeURIComponent(key)}`, preferencesUpdate)).data;
}

async function upsertPreferences<T>(key:string, preferencesUpdate: T): Promise<T> {
  return (await axios.patch(`/api/preferences/${encodeURIComponent(key)}`, preferencesUpdate)).data;
}

async function getPreferences<T>(key:string): Promise<T | null> {
  return (await axios.get(`/api/preferences/${encodeURIComponent(key)}`)).data;
}

export default {
  overwritePreferences,
  upsertPreferences,
  getPreferences,
};
