import { NewsWidget } from '@/widgets/news-widget';
import React from 'react';
import { WidgetHost } from '@/widgets/widget-host';
import Events from '@/components/calendars/events';
import { RouteObject } from 'react-router-dom';
import { NewsWidgetFactory, WidgetMenuItem } from '@/widgets/news-widget-factory';
import MultiCalendar from '@/components/calendars/multi-calendar';
import SingleCalendar from '@/components/calendars/single-calendar';
import SingleCalendarTitle from '@/components/calendars/single-calendar-title';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routeMatches } from '@/widgets/widget-router/route-matches';

const urlRegex = /\/events\/([a-zA-Z0-9-]+)/;
const urlPath = '/events/:calendarSlug';

export class SingleCalendarWidgetFactory implements NewsWidgetFactory {
  get path(): string {
    return urlPath;
  }

  get headerPanel(): string | JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faCalendar} /> Calendars
      </>
    );
  }

  matchesTemplate(s: string): boolean {
    return !!routeMatches(urlPath, s);
  }

  createInstance(s: string): NewsWidget {
    const match = routeMatches(urlPath, s);

    if (match) return new SingleCalendarWidget(match.calendarSlug);

    throw new Error('invalid url');
  }

  getListableUrls(): WidgetMenuItem[] {
    return [
      {
        url: '/events/key-events-calendar',
        name: 'Key Events Calendar',
      },
      {
        url: '/events/fomc-meeting-calendar',
        name: 'FOMC Meeting Calendar',
      },
      {
        url: '/events/boe-meeting-calendar',
        name: 'BOE Meeting Calendar',
      },
    ];
  }
}

export class SingleCalendarWidget implements NewsWidget {
  titleText: string = 'Calendar';
  constructor(private calendar: string) {}

  async hasPermission(permissions: string[]) {
    return true;
  }

  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faCalendar} /> <SingleCalendarTitle calendarSlug={this.calendar} />
      </>
    );
  }

  get id(): string {
    return '/events/' + this.calendar;
  }

  initialize(host: WidgetHost): void {}

  get shortTitle(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faCalendar} /> {this.calendar}
      </>
    );
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '/',
        element: <SingleCalendar calendarSlug={this.calendar} />,
      },
    ];
  }

  get defaultRoute(): string {
    return `/`;
  }
}
