import { ConceptItem, qCode } from '@/model/concept-item';
import { Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Section } from '@/model/news/section';
import useWidgetPreferences from '@/widgets/widget-preferences/use-widget-preferences';

const sanitizeName = (s: string) => {
  return s.replaceAll(/bullet(s)?|news/gi, '').trim();
};

interface ScrollerFilterPreferences {
  includedSections:string[]
}

export default function SectionFilters({
  sections,
  onFilteredSectionsChange,
  onFilteredDataNameChange,
}: {
  sections: Section[];
  onFilteredSectionsChange: (s: string[]) => void;
  onFilteredDataNameChange: (s?: string) => void;
}) {

  const {saveWidgetPreferences, getWidgetPreferences, preferencesKey} = useWidgetPreferences<ScrollerFilterPreferences>();

  const [state, setState] = useState<{includedSections:string[], dirty:boolean}>();

  useEffect(() => {

    getWidgetPreferences().then((value) => {
      setState({
        includedSections: value?.includedSections || sections.map(value => value.code),
        dirty:false
      })

    })

  }, [sections, preferencesKey]);

  useEffect(() => {
    if (!state)
      return;

    if (state.dirty) {
      saveWidgetPreferences({
        includedSections: state.includedSections
      }).then(value => console.log("Saved preferences"))
    }

    onFilteredSectionsChange(state.includedSections);

  }, [state]);


  useEffect(() => {
    if (!state)
      return;

    if (state.includedSections.length == sections.length) onFilteredDataNameChange(undefined);
    else {
      if (!sections) return;

      const remaining = sections
        .filter(conceptItem => {
          const qcode = `${conceptItem.code}`;

          return state.includedSections.includes(qcode);
        })
        .map(value => sanitizeName(value.name));

      let title = '';

      if (remaining.length == 1) {
        title = remaining[0];
      } else if (remaining.length > 1 && remaining.length <= 4) {
        const front = remaining.slice(0, remaining.length - 1);
        const back = remaining.slice(remaining.length - 1, remaining.length);
        title = front.join(', ') + ' and ' + back;
      }

      onFilteredDataNameChange(title);
    }
  }, [sections, state]);

  const filtersLeft = () => {
    return state?.includedSections.length || 1;
  };

  const excludeSubject = (s: string, exclude: boolean) => {

    if (!state)
      return;

    if (exclude && filtersLeft() <= 1) return;

    let result: string[];

    if (exclude) result = state.includedSections.filter(value => value != s);
    else result = [...state.includedSections, s];

    setState({includedSections:result,dirty:true});

  };

  const SubjectSwitch = ({ value }: { value: Section }) => {
    return (
      <Form.Check
        type={'switch'}
        onChange={event => excludeSubject(value.code, !event.target.checked)}
        checked={state?.includedSections.includes(value.code)}
        label={sanitizeName(value.name)}
        className={'d-inline-block pb-0 mb-0 me-1'}
        style={{ minHeight: 0 }}
      />
    );
  };

  return (
    <>
      {sections.map(value => (
        <SubjectSwitch value={value} key={'ci' + value.code} />
      ))}
    </>
  );
}
