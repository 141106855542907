import { NewsWidgetFactory, WidgetMenuEntry, WidgetMenuItem } from '@/widgets/news-widget-factory';
import { NewsWidget } from '@/widgets/news-widget';
import React from 'react';
import { PinnedSeriesWidget } from '@/widgets/widgets/pinned/pinned-series-widget';
import { routeMatches } from '@/widgets/widget-router/route-matches';
import { PinnedArticleWidget } from '@/widgets/widgets/pinned/pinned-article-widget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';

export class PinnedSeriesWidgetFactory implements NewsWidgetFactory {
  getListableUrls(): WidgetMenuEntry[] {
    return [
      {
        name: 'Central Bank Previews',
        children: [
          { name: 'Any DM Preview', url: '/pinned-series/mni-section:513539744' },
          {
            name: 'DM Central Banks',
            children: [
              { name: 'BoC - Preview', url: '/pinned-series/mni-section:504988107' },
              { name: 'BoE - Preview', url: '/pinned-series/mni-section:504988112' },
              { name: 'BoJ - Preview', url: '/pinned-series/mni-section:504988113' },
              { name: 'ECB - Preview', url: '/pinned-series/mni-section:504988110' },
              { name: 'Fed - Preview', url: '/pinned-series/mni-section:504988115' },
              { name: 'Norges - Preview', url: '/pinned-series/mni-section:504988116' },
              { name: 'RBA - Preview', url: '/pinned-series/mni-section:504988106' },
              { name: 'RBNZ - Preview', url: '/pinned-series/mni-section:504988114' },
              { name: 'Riksbank - Preview', url: '/pinned-series/mni-section:504988108' },
              { name: 'SNB - Preview', url: '/pinned-series/mni-section:504988111' },
            ],
          },
          {
            name: 'EM Central Banks',
            children: [
              { name: 'Asian Bank Previews', url: '/pinned-series/mni-section:538093857' },
              { name: 'CEMEA Bank Previews', url: '/pinned-series/mni-section:538093850' },
              { name: 'LatAm Bank Previews', url: '/pinned-series/mni-section:538093858' },
            ],
          },
        ],
      },
      {
        name: 'Central Bank Reviews',
        children: [
          {
            name: 'DM Central Banks',
            children: [
              { name: 'BoC - Review', url: '/pinned-series/mni-section:509000520' },
              { name: 'BoE - Review', url: '/pinned-series/mni-section:509000523' },
              { name: 'BoJ - Review', url: '/pinned-series/mni-section:509000524' },
              { name: 'ECB - Review', url: '/pinned-series/mni-section:504988109' },
              { name: 'Fed - Review', url: '/pinned-series/mni-section:509000526' },
              { name: 'Norges - Review', url: '/pinned-series/mni-section:509000527' },
              { name: 'RBA - Review', url: '/pinned-series/mni-section:509000519' },
              { name: 'RBNZ - Review', url: '/pinned-series/mni-section:509000525' },
              { name: 'Riksbank - Review', url: '/pinned-series/mni-section:509000521' },
              { name: 'SNB - Review', url: '/pinned-series/mni-section:509000522' },
            ],
          },
          {
            name: 'EM Central Banks',
            children: [
              { name: 'Asian Bank Reviews', url: '/pinned-series/mni-section:538093871' },
              { name: 'CEMEA Bank Reviews', url: '/pinned-series/mni-section:538093870' },
              { name: 'LatAm Bank Reviews', url: '/pinned-series/mni-section:538093872' },
            ],
          },
        ],
      },
      {
        name: 'Central Bank Data Watch Analysis',
        children: [
          { name: 'BoC - Central Bank Data Watch', url: '/pinned-series/mni-section:510709462' },
          { name: 'BoE - Central Bank Data Watch', url: '/pinned-series/mni-section:510709465' },
          { name: 'CBR - Central Bank Data Watch', url: '/pinned-series/mni-section:510709459' },
          { name: 'CBRT - Central Bank Data Watch', url: '/pinned-series/mni-section:509103849' },
          { name: 'ECB - Central Bank Data Watch', url: '/pinned-series/mni-section:510709464' },
          { name: 'Fed - Central Bank Data Watch', url: '/pinned-series/mni-section:510709461' },
          { name: 'NBH - Central Bank Data Watch', url: '/pinned-series/mni-section:509104164' },
          { name: 'NBP - Central Bank Data Watch', url: '/pinned-series/mni-section:509104369' },
          {
            name: 'Norges Bank - Central Bank Data Watch',
            url: '/pinned-series/mni-section:509102979',
          },
          { name: 'PBoC - Central Bank Data Watch', url: '/pinned-series/mni-section:509103389' },
          { name: 'RBNZ - Central Bank Data Watch', url: '/pinned-series/mni-section:510709467' },
          {
            name: 'Riksbank - Central Bank Data Watch',
            url: '/pinned-series/mni-section:509103157',
          },
          { name: 'SARB - Central Bank Data Watch', url: '/pinned-series/mni-section:509103592' },
          { name: 'SNB - Central Bank Data Watch', url: '/pinned-series/mni-section:509102570' },
        ],
      },
      {
        name: 'Global Macro Analysis',
        children: [
          { name: 'Balance Sheet Analysis', url: '/pinned-series/mni-section:551192113' },
          { name: 'Employment Insight', url: '/pinned-series/mni-section:2257094554' },
          { name: 'Inflation Insight', url: '/pinned-series/mni-section:551192114' },
          { name: 'Macro Chart Pack', url: '/pinned-series/mni-section:2257094548' },

          { name: 'Data Analysis - Eurozone', url: '/pinned-series/mni-section:2257094551' },
          { name: 'Data Analysis - UK', url: '/pinned-series/mni-section:2257094552' },
          { name: 'Data Analysis - US', url: '/pinned-series/mni-section:2257140969' },
        ],
      },
      {
        name: 'Political Risk Coverage',
        children: [
          { name: 'Election Previews', url: '/pinned-series/mni-section:515660945' },
          { name: 'Political Risk - US Daily Brief', url: '/pinned-series/mni-section:600166007' },
          { name: 'Political Risk Analysis', url: '/pinned-series/mni-section:500163514' },
          { name: 'Political Risk Timelines', url: '/pinned-series/mni-section:515660944' },
          { name: 'Political Risk Week Ahead', url: '/pinned-series/mni-section:518382770' },
        ],
      },
      {
        name: 'Commodities Analysis',
        children: [
          { name: 'Commodities Research', url: '/pinned-series/mni-section:561375749' },
          { name: 'Commodities Weekly Analysis', url: '/pinned-series/mni-section:2148748890' },
          { name: 'NatGas & LNG Analysis', url: '/pinned-series/mni-section:2255207223' },
          { name: 'Refinery Maintenance Schedule', url: '/pinned-series/mni-section:2248993921' },
        ],
      },
      {
        name: 'Credit Coverage',
        children: [{ name: 'Credit Analysis', url: '/pinned-series/mni-section:2243823041' }],
      },
    ];
  }

  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faNewspaper} /> Article Series
      </>
    );
  }

  matchesTemplate(s: string): boolean {
    return !!routeMatches(this.path, s);
  }

  createInstance(s: string): NewsWidget {
    const match = routeMatches(this.path, s);

    if (match) return new PinnedSeriesWidget(match.genreQCode);

    throw new Error('invalid url');
  }
  get path(): string {
    return '/pinned-series/:genreQCode';
  }
}
