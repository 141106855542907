export const breakpoints: { [k: string]: number } = {
  lg: 1800,
  md: 1200,
  sm: 900,
  xs: 100,
  xxs: 0,
};

export function getBreakpointFromElement(current?: HTMLElement | null) {
  const width = current?.clientWidth || -1;

  const breakpointsArray = [];

  for (let breakpointsKey in breakpoints) {
    breakpointsArray.push({
      key: breakpointsKey,
      value: breakpoints[breakpointsKey],
    });
  }

  const sortedBreakpoints = breakpointsArray.sort((a, b) => a.value - b.value);

  let breakpoint: string | undefined = undefined;

  sortedBreakpoints.forEach(value => {
    if (width > value.value) breakpoint = value.key;
  });
  return breakpoint;
}
