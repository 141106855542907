export enum MessageServiceConstants {
  SELECT_TOPIC = '/topic/observations',
  INSTANT_ANSWERS_TOPIC = '/topic/instant-answers',
  AUCTION_RESULTS_TOPIC = '/topic/auction-results',
  NEWS_ARTICLE_TOPIC = '/topic/news/articles',
  API_RESPONSE_ERRORS = '/local/api-responses',
  MESSAGE_CONNECTION_STATE = '/local/connection-state',
  BOOKMARKS_CHANGED = '/local/bookmarks-changed',
  WIDGET_NOTIFICATION_ACKNOWLEDGED = '/local/widget-notification-acknowledged',
}
