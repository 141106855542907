import Scroller from '@/components/scrollers/scroller';
import { useEffect, useState } from 'react';
import { Article } from '@/model/article';
import dayjs from 'dayjs';
import ArticleView from '@/components/articles/article-view';
import WidgetLink from '@/widgets/widget-router/widget-link';
import { ConceptItemConstants } from '@/model/concept-item';
import AgedDateFormat from '@/components/shared/aged-date-format';
import styles from './mainwire-scroller.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import EmptyStateIndicator from '@/components/shared/empty-state-indicator';
import PinButton from '@/components/pins/pin-button';
import BookmarkButton from '@/components/bookmarks/bookmark-button';
import { opinionsApi } from '@/api';
import { OpinionMap } from '@/model/opinions/opinion-map';
import articleListStyles from '@/components/articles/article-list.module.scss';
export default function MainwireScroller({ section }: { section: string[] }) {
  const [articles, setArticles] = useState<Article[]>();
  const [selectedArticle, setSelectedArticle] = useState<Article>();
  const [opinions, setOpinions] = useState<OpinionMap>({});

  useEffect(() => {
    if (articles)
      opinionsApi.getOpinionsOfArticles(...articles.map(value => value.uri)).then(setOpinions);
  }, [articles]);

  if (selectedArticle)
    return (
      <div className={'h-100 overflow-auto'}>
        <ArticleView article={selectedArticle} onClose={() => setSelectedArticle(undefined)} />
      </div>
    );

  const isBelled = (a: Article) => {
    return !!a.genre.find(value => {
      return `${value.scheme}:${value.code}` == ConceptItemConstants.BELL;
    });
  };

  const flash = (index: number, date: string) => {
    if (index === 0 && dayjs(date).isAfter(dayjs().subtract(5, 'minute')))
      return articleListStyles.flashArticle;

    return '';
  };

  return (
    <Scroller section={section} onArticleClick={setSelectedArticle} onArticlesChange={setArticles}>
      <table className={'table overflow-auto table-sm table-hover '}>
        <tbody>
          {articles?.map((value, index) => {
            const flashClass = flash(index, value.versioncreated);

            return (
              <tr key={'mw-' + value.uri} className={`${flashClass} align-middle`}>
                <td className={`text-nowrap text-muted ${flashClass}`}>
                  <AgedDateFormat format={'h:mm a'} date={value.versioncreated} />
                </td>
                <td className={`${styles.mainNormalItem} fw-bold w-100 ${flashClass}`}>
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <WidgetLink
                      to={value.uri}
                      className={`text-decoration-none ${isBelled(value) ? 'link-danger fw-bold' : ''}`}
                    >
                      {isBelled(value) && <FontAwesomeIcon icon={faBell} />} {value.headline}
                    </WidgetLink>
                    <div className={`text-end ${flashClass} flex-shrink-0`}>
                      <PinButton article={value} />
                      <BookmarkButton article={value} providedOpinions={opinions} />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {articles?.length == 0 && (
        <div className={'w-100 h-75 d-flex flex-column align-items-center justify-content-around'}>
          <div className={'w-25'}>
            <EmptyStateIndicator text={'No More items'} />
          </div>
        </div>
      )}
    </Scroller>
  );
}
