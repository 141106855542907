import { createContext } from 'react';
import { WidgetHost } from '@/widgets/widget-host';
import { Notification } from '@/widgets/notifications/notification';

export const WidgetHostContext = createContext<WidgetHost>({
  addWidgetById(id: string) {
    console.warn('Noop widget host called');
  },
  removeWidgetById(id: string) {
    console.warn('Noop widget host called');
  },
  get activeWidgetIds(): string[] {
    return [];
  },
  get hostId(): string {
    return 'no-host'
  },
  get currentViewId(): string {
    return 'default-view'
  }
});
