import { createContext } from 'react';
import { WidgetContainer } from '@/widgets/widget-container';

export const WidgetContainerContext = createContext<WidgetContainer>({
  setTitle(title?: string | JSX.Element) {
    console.warn('Noop widget container called', 'setTitle', title);
  },
  get widgetId(): string | undefined {
    return undefined;
  },
});
