import React from 'react';
import BookmarksList from '../../components/bookmarks/bookmark-list';
import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';
import ArticleViewPage from '@/pages/news/article-view-page';
import { articleApi } from '@/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';

export class BookmarksWidget implements NewsWidget {
  titleText: string = 'Bookmarks';

  readonly id = '/bookmarks';

  initialize(host: WidgetHost) {}
  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faBookmark} /> Bookmarks
      </>
    );
  }

  async hasPermission(permissions: string[]) {
    return true;
  }

  get mainPanel(): JSX.Element {
    return <BookmarksList />;
  }

  get shortTitle(): JSX.Element {
    return (
      <>
        <i className="fa-solid fa-bolt"></i> Bookmarks
      </>
    );
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '',
        element: <BookmarksList />,
      },
      {
        path: ':articleId',
        element: <ArticleViewPage />,
        loader: async ({ params }) => {
          try {
            return await articleApi.getArticle(params.articleId || '');
          } catch (e) {
            return e;
          }
        },
      },
    ];
  }

  get defaultRoute(): string {
    return '';
  }
}
