import { Layout, Layouts } from 'react-grid-layout';
import { MNIDashboard } from '@/model/preferences/myMNIPreferences';
import { compareLayout, compareLayouts } from '@/components/dashboard/dashboard-util';
import { Reducer, useState } from 'react';

export type Breakpoint = 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
export type DashboardState = {
  loading: boolean;
  editing: boolean;
  dirty: boolean;
  saveWhileEditing?: boolean;
  dashboards: MNIDashboard[];
  selectedDashboard?: string;
  currentBreakpoint?: Breakpoint;
  lastLayout: Layouts;
  lastSavedDashboards?: MNIDashboard[];
  highlightedWidgets: { [k: string]: boolean };
};

export type DashboardAction =
  | { type: 'dashboardsLoaded'; dashboards: MNIDashboard[] }
  | { type: 'recordSavedDashboards'; dashboards?: MNIDashboard[] }
  | { type: 'selectDashboard'; id: string }
  | { type: 'addWidget'; id: string }
  | { type: 'highlightWidget'; id: string }
  | { type: 'unHighlightWidget'; id: string }
  | { type: 'removeWidget'; id: string }
  | { type: 'setCurrentDashboardName'; name: string }
  | { type: 'createDashboard'; name: string; id: string }
  | { type: 'deleteCurrentDashboard' }
  | { type: 'stopEditingCurrentDashboard' }
  | { type: 'saveCurrentDashboard' }
  | { type: 'editCurrentDashboard' }
  | { type: 'setBreakpoint'; breakpoint: Breakpoint }
  | { type: 'setLayouts'; layout: Layouts; breakpoint: Breakpoint }
  | { type: 'failure'; error: string };

const log = (...args: any[]) => {
  console.warn('REDUCER', ...args);
};

export type DashboardContentReducer = Reducer<DashboardState, DashboardAction>;

export const dashboardContentReducerWrapper: Reducer<DashboardState, DashboardAction> = (
  state: DashboardState,
  action: DashboardAction,
): DashboardState => {
  log('reducer action', action);

  const result = dashboardContentReducer(state, action);

  log('reducer result', result);

  return result;
};
export function dashboardContentReducer(
  state: DashboardState,
  action: DashboardAction,
): DashboardState {
  const selectedDashboard =
    state.dashboards && state.dashboards.find(value => value.id == state.selectedDashboard);
  const selectedDashboardIndex =
    state.dashboards && state.dashboards.findIndex(value => value.id == state.selectedDashboard);

  switch (action.type) {
    case 'dashboardsLoaded': {
      const savedDashboardId = localStorage.getItem('selectedDashboard');

      console.info('Using selectred dashboard', savedDashboardId);

      let selectedDashboardId: string | undefined = undefined;

      if (action.dashboards.length > 0) {
        selectedDashboardId = action.dashboards.find(value => value.id == savedDashboardId)?.id;

        if (!selectedDashboard) selectedDashboardId = action.dashboards[0].id;
      } else log('no dashboards');

      return {
        loading: false,
        editing: false,
        dirty: false,
        dashboards: [...action.dashboards],
        selectedDashboard: selectedDashboardId,
        lastLayout: {} as Layouts,
        lastSavedDashboards: JSON.parse(JSON.stringify(action.dashboards)),
        highlightedWidgets: {},
      };
    }
    case 'recordSavedDashboards': {
      return {
        ...state,
        lastSavedDashboards: JSON.parse(JSON.stringify(action.dashboards)),
      };
    }
    case 'highlightWidget':
      state.highlightedWidgets[action.id] = true;

      return {
        ...state,
        highlightedWidgets: {
          ...state.highlightedWidgets,
        },
      };
    case 'unHighlightWidget':
      state.highlightedWidgets[action.id] = false;

      return {
        ...state,
        highlightedWidgets: {
          ...state.highlightedWidgets,
        },
      };

    case 'selectDashboard':
      if (!state.dashboards) return state;

      return {
        ...state,
        selectedDashboard: action.id,
      };

    case 'addWidget': {
      if (!state.dashboards || !selectedDashboard) {
        console.error('Cannot add widget', state);
        return state;
      }

      const currentBreakpoint = state.currentBreakpoint || 'md';

      if (!selectedDashboard.layouts[currentBreakpoint])
        selectedDashboard.layouts[currentBreakpoint] = [];

      const layout = selectedDashboard.layouts[currentBreakpoint];

      if (!layout.find(value => value.i == action.id)) {
        layout.push({ w: 4, h: 4, x: 0, y: -1, i: action.id });
        log('Adding widget to layout', layout);
      }

      selectedDashboard.layouts[currentBreakpoint] = [...layout];
      state.dashboards[selectedDashboardIndex] = { ...selectedDashboard };

      return {
        ...state,
        dashboards: [...state.dashboards],
      };
    }
    case 'removeWidget': {
      if (!selectedDashboard) return state;
      for (let breakpoint in selectedDashboard.layouts) {
        if (!selectedDashboard.layouts[breakpoint]) selectedDashboard.layouts[breakpoint] = [];

        selectedDashboard.layouts[breakpoint] = selectedDashboard.layouts[breakpoint].filter(
          value => value.i != action.id,
        );
      }

      selectedDashboard.layouts = { ...selectedDashboard.layouts };
      state.dashboards[selectedDashboardIndex] = { ...selectedDashboard };

      return {
        ...state,
        dashboards: [...state.dashboards],
      };
    }
    case 'setCurrentDashboardName':
      if (selectedDashboard) selectedDashboard.name = action.name;

      return {
        ...state,
        dashboards: [...state.dashboards],
        dirty: true,
      };

    case 'editCurrentDashboard':
      if (!state.selectedDashboard) return state;
      return {
        ...state,
        editing: true,
      };
    case 'stopEditingCurrentDashboard':
      return {
        ...state,
        editing: false,
        dirty: false,
        saveWhileEditing: false,
      };

    case 'saveCurrentDashboard':
      return {
        ...state,
        dirty: true,
        saveWhileEditing: true,
      };
    case 'createDashboard':
      if (!state.dashboards) return state;

      if (state.dashboards.some(value => value.id == action.id)) return state;

      const newDashboard = {
        name: action.name,
        id: action.id,
        layouts: {},
      };
      state.dashboards.push(newDashboard);

      state.selectedDashboard = newDashboard.id;

      return {
        ...state,
        dashboards: [...state.dashboards],
      };
    case 'deleteCurrentDashboard': {
      if (state.dashboards) {
        const updatedDashboards = state.dashboards.filter(
          value => value.id != state.selectedDashboard,
        );

        state.selectedDashboard =
          updatedDashboards && updatedDashboards.length > 0 ? updatedDashboards[0].id : undefined;

        log('Remaining', updatedDashboards);

        state.dashboards = updatedDashboards;
      }

      return {
        ...state,
        dirty: true,
      };
    }
    case 'setBreakpoint':
      return {
        ...state,
        currentBreakpoint: action.breakpoint,
      };
    case 'setLayouts':
      if (!selectedDashboard || !selectedDashboard.layouts) return state;

      const oldLayout = JSON.parse(JSON.stringify(selectedDashboard.layouts)) as Layouts;
      let hasChanges = false;
      const newWidgets = action.layout[action.breakpoint].map(value => value.i);
      const oldWidgets = selectedDashboard.layouts[action.breakpoint]?.map(value => value.i) || [];

      if (
        newWidgets.length != oldWidgets.length ||
        !oldWidgets.every(value => newWidgets.includes(value)) ||
        action.breakpoint == state.currentBreakpoint
      ) {
        log('Contents have changed, applying new layout');
        selectedDashboard.layouts[action.breakpoint] = action.layout[action.breakpoint];

        hasChanges = true;
      } else {
        log('No content changes ignoring new layout');
      }

      let dirty =
        !compareLayouts(state.lastLayout, action.layout) &&
        action.breakpoint != 'xxs' &&
        action.breakpoint != 'xs';

      return {
        ...state,
        lastLayout: oldLayout,
        dirty: state.dirty || dirty,
      };
    default:
      return state;
  }
}
