import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchPanel from '@/components/search/search-panel';
import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';
import ArticleViewPage from '@/pages/news/article-view-page';
import {articleApi} from '@/api';

export class SearchWidget implements NewsWidget {
  titleText: string = 'Search';

  readonly id = '/search';

  initialize(host: WidgetHost) {}

  async hasPermission(permissions: string[]) {
    return true;
  }

  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faSearch} /> Search
      </>
    );
  }

  get shortTitle(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faSearch} />
      </>
    );
  }

  get mainPanel(): JSX.Element {
    return <SearchPanel />;
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '',
        element: <SearchPanel />,
      },
      {
        path: ':articleId',
        element: <ArticleViewPage />,
        loader: async ({ params }) => {
          try {
            return await articleApi.getArticle(params.articleId || '');
          } catch (e) {
            return e;
          }
        },
      },
    ];
  }

  get defaultRoute(): string {
    return '';
  }
}
