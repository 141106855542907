import {useContext} from 'react';
import {WidgetContainer} from '@/widgets/widget-container';
import {WidgetContainerContext} from '@/widgets/widget-container/widget-container-context';
import {preferencesApi} from '@/api';
import {WidgetHost} from '@/widgets/widget-host';
import {WidgetHostContext} from '@/widgets/widget-host/widget-host-context';


interface WidgetPreferencesService<T> {
  getWidgetPreferences():Promise<T|null>;
  saveWidgetPreferences(p:T):Promise<T>;
  mergeWidgetPreferences(p:T):Promise<T>;
  preferencesKey:string;
}

export default function useWidgetPreferences<T>():WidgetPreferencesService<T>{


  const {widgetId} = useContext<WidgetContainer>(WidgetContainerContext);
  const {hostId, currentViewId} = useContext<WidgetHost>(WidgetHostContext);
  const preferencesKey = `widget-preferences-${hostId}-${currentViewId}-${widgetId}`


  return {
    async getWidgetPreferences(): Promise<T | null> {
      return preferencesApi.getPreferences<T>(preferencesKey)
    },
    async saveWidgetPreferences(p: T): Promise<T> {
      return preferencesApi.overwritePreferences<T>(preferencesKey, p)
    },
    async mergeWidgetPreferences(p: T): Promise<T> {
      return preferencesApi.upsertPreferences<T>(preferencesKey, p)
    },
    preferencesKey
  }


}
