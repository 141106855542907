import React from 'react';
import MainwireScroller from '@/components/scrollers/mainwire/mainwire-scroller';
import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';
import ArticleViewPage from '@/pages/news/article-view-page';
import { articleApi, sectionApi } from '@/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { BaseScroller } from '@/widgets/widgets/scrollers/base-scroller';

export class MainwireScrollerWidget extends BaseScroller {
  constructor(id: string, section: string[], title: string, _shortTitle: string) {
    super(`/bullets/${id}`, section, title, _shortTitle);
  }
  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faRectangleList} /> {this.titleText}
      </>
    );
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '',
        element: <MainwireScroller section={this.section} />,
      },
      {
        path: ':articleId',
        element: <ArticleViewPage />,
        loader: async ({ params }) => {
          try {
            return await articleApi.getArticle(params.articleId || '');
          } catch (e) {
            return e;
          }
        },
      },
    ];
  }

  get defaultRoute(): string {
    return '/';
  }
}
