import { useEffect, useState } from 'react';
import { GridLoader } from 'react-spinners';
import dayjs from 'dayjs';
import useSubscription from '@/hooks/use-subscription';
import useTimer from '@/hooks/use-timer';
import { Article } from '@/model/article';
import theme from '@/index.module.scss';
import UpgradePanel from '@/components/shared/upgrade-panel';
import PageControl from '@/components/shared/page-control';
import AgedDateFormat from '@/components/shared/aged-date-format';
import TaggedHeadline from '@/components/shared/tagged-headline';

import styles from '@/components/articles/article-view.module.scss';
import articleListStyles from '@/components/articles/article-list.module.scss';
import {articleApi, opinionsApi} from '@/api';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import ArticleBodyDisplay from '@/components/articles/article-body-display';
import InfiniScroller from '@/components/scrollers/infini-scroller';
import PinButton from '@/components/pins/pin-button';
import BookmarkButton from '@/components/bookmarks/bookmark-button';
import {OpinionMap} from '@/model/opinions/opinion-map';

interface BulletListProps {
  section: string[];
  articles?: Article[];

  onArticleClick: Function;
  dark?: boolean;
  title?: JSX.Element;
}

export default function BulletList(props: BulletListProps) {
  const [articles, setArticles] = useState<Article[]>();
  const [forbidden, setForbidden] = useState<boolean>(false);
  const [opinions, setOpinions] = useState<OpinionMap>({});

  if (forbidden)
    return (
      <UpgradePanel
        heading={'Contact us to read more'}
        leadMessage={'I would like to try: ' + props.section}
      />
    );

  const loadOpinionsOfBatch = (a:Article[]) =>{

    opinionsApi.getOpinionsOfArticles(...a.map(value => value.uri)).then(value => {
      for (let valueKey in value) {
        opinions[valueKey] = value[valueKey];
      }

      setOpinions({...opinions})
    });

  }

  const flash = (index: number, date: string) => {
    if (index === 0 && dayjs(date).isAfter(dayjs().subtract(5, 'minute')))
      return articleListStyles.flashArticle;

    return '';
  };

  return (
    <InfiniScroller section={props.section} onArticlesChange={setArticles} onBatchLoad={loadOpinionsOfBatch}>
      {articles?.map((value, index) => (
        <div key={'b-' + value.uri} className={`${flash(index, value.versioncreated)}`}>
          <div className={'d-flex justify-content-between align-items-baseline'}>
            <div>
              <AgedDateFormat format={'h:mm a'} date={value.versioncreated} scale={10} />:{' '}
              <strong className="ms-3">
                <TaggedHeadline headline={value.headline} />
              </strong>
            </div>
            <div className={'flex-shrink-0'}>
              <PinButton article={value} />
              <BookmarkButton article={value} providedOpinions={opinions}/>
            </div>
          </div>
          <div>
            <small className={'text-text-dark ' + styles.article}>
              <ArticleBodyDisplay xhtml={value.body_xhtml} />
            </small>
          </div>
          <div className={'mb-2 small'}></div>
        </div>
      ))}
    </InfiniScroller>
  );
}
