import { NewsWidget } from '@/widgets/news-widget';
import { WidgetHost } from '@/widgets/widget-host';
import { RouteObject } from 'react-router-dom';
import { sectionApi } from '@/api';
import React from 'react';

export abstract class BaseScroller implements NewsWidget {
  _titleText: string = '';
  readonly defaultTitle;
  protected constructor(
    readonly id: string,
    protected section: string[],
    protected title: string,
    protected _shortTitle: string,
  ) {
    this._titleText = title;
    this.defaultTitle = title;
  }
  async hasPermission(permissions: string[]) {
    const section = await Promise.allSettled(
      this.section.map(value => sectionApi.getSectionByCode(value)),
    );

    return section.some(value => value.status == 'fulfilled' && value.value.flags.authorized);
  }

  set titleText(s: string | undefined) {
    if (s) this._titleText = s;
    else this._titleText = this.defaultTitle;
  }

  get titleText(): string | undefined {
    return this._titleText;
  }

  initialize(host: WidgetHost) {}

  get shortTitle(): JSX.Element {
    return <>{this._shortTitle}</>;
  }

  abstract get defaultRoute(): string;

  abstract get headerPanel(): JSX.Element;

  abstract get routes(): RouteObject[];
}
