import { NewsWidget } from '@/widgets/news-widget';
import React from 'react';
import { useWidgetPermission } from '@/hooks/use-widget-permission';
import { Button } from 'react-bootstrap';
import LocalWidgetRouter from '@/widgets/widget-router/local-widget-router';

export default function HasWidgetPermissions({
  widget,
  children,
}: React.PropsWithChildren<{ widget: NewsWidget }>) {
  const { authorized, loadingPermission } = useWidgetPermission(widget);

  return (
    <>
      {!loadingPermission && (
        <>
          {!authorized && (
            <div className={'h-100 d-flex flex-column justify-content-around text-center'}>
              <div>
                <h3>Upgrade to access this item</h3>
                <Button variant={'warning'}>Contact us</Button>
              </div>
            </div>
          )}
          {authorized && <>{children}</>}
        </>
      )}
    </>
  );
}
