import DashboardWidgetPanel from '@/components/dashboard/dashboard-widget-panel';
import { Context } from '@/App';
import { ReducerState, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { NewsWidget } from '@/widgets/news-widget';
import useWidgetsFromLayouts, {
  ActiveWidgetsForDashboard,
} from '@/components/dashboard/hooks/use-widgets-from-layouts';
import {
  breakpoints,
  getBreakpointFromElement,
} from '@/components/dashboard/dashboard-breakpoints';
import {
  Breakpoint,
  DashboardAction,
} from '@/components/dashboard/store/dashboard-content-reducer';
import EmptyStateIndicator from '@/components/shared/empty-state-indicator';
import DashboardAddItemControl from '@/components/dashboard/dashboard-add-item-control';
import { AppContext } from '@/model/app-context';

export default function DashboardContent({
  layouts,
  editMode,
  cols,
  dispatch,
  activeWidgets,
  highlightedWidgets,
}: {
  layouts: Layouts;
  editMode: boolean;
  activeWidgets: ActiveWidgetsForDashboard;
  cols: any;
  dispatch: (a: DashboardAction) => void;
  highlightedWidgets: { [k: string]: boolean };
}) {
  const [currentBreakpoint, setCurrentBreakPoint] = useState<Breakpoint>();
  const ref = useRef<HTMLDivElement | null>(null);
  const [internalLayouts, setInternalLayouts] = useState<Layouts>();
  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const context = useContext<AppContext>(Context);

  useEffect(() => {
    if (currentBreakpoint) dispatch({ type: 'setBreakpoint', breakpoint: currentBreakpoint });
  }, [currentBreakpoint]);

  useEffect(() => {
    console.info('activeWidgets Dashboard change', activeWidgets);
  }, [activeWidgets]);

  useEffect(() => {
    console.info('layouts Dashboard change', layouts);
    setInternalLayouts(layouts);
  }, [layouts]);

  const getLayoutForWidget = (id: string): { x: number; y: number; w: number; h: number } => {
    //console.log('Getting layouts for ', currentBreakpoint, id);

    const savedPosition =
      internalLayouts && internalLayouts[currentBreakpoint || '']?.find(value => value.i === id);

    //console.log('Found layouts for ', currentBreakpoint, id, savedPosition);

    if (savedPosition) return savedPosition;

    console.log('FALLBACK POSITION FOR COMPONENT');

    return { x: 0, y: 0, w: 4, h: 4 };
  };

  function _preventTextSelect(a: any, b: any, c: any, d: any, event: MouseEvent) {
    event.preventDefault();
  }

  useEffect(() => {
    if (ref.current) {
      setCurrentBreakPoint(getBreakpointFromElement(ref.current));
    }
  }, [ref, ref.current]);

  const onLayoutChange = (_currentLayout: Layout[], allLayouts: Layouts) => {
    const breakpoint = Object.keys(allLayouts).find(value => allLayouts[value] === _currentLayout);

    console.warn('Layout change', allLayouts, breakpoint);

    if (breakpoint != currentBreakpoint) {
      console.error('Change is for another breakpoint', breakpoint, currentBreakpoint);
    }

    dispatch({
      type: 'setLayouts',
      layout: allLayouts,
      breakpoint: breakpoint as Breakpoint,
    });
  };

  const widgetPanels = useMemo(
    () =>
      activeWidgets?.activeWidgets?.map(value => {
        if (!currentBreakpoint || !activeWidgets || !internalLayouts) {
          console.log('Not rendering........', currentBreakpoint, activeWidgets, internalLayouts);
          return <span key={value.id}></span>;
        }

        const layoutForWidget = getLayoutForWidget(value.id);
        return (
          <div
            key={value.id}
            data-grid={layoutForWidget}
            className={'overflow-hidden responsive-layout'}
          >
            <DashboardWidgetPanel
              highlight={highlightedWidgets[value.id]}
              widget={value}
              editMode={editMode}
              onClose={() => {
                dispatch({ type: 'removeWidget', id: value.id });
              }}
              onHeaderDoubleClick={() => {
                dispatch({ type: 'editCurrentDashboard' });
              }}
              onSave={() => {
                dispatch({ type: 'saveCurrentDashboard' });
              }}
            />
          </div>
        );
      }),
    [activeWidgets, editMode, currentBreakpoint, highlightedWidgets],
  );

  const breakpointChange = (newBreakpoint: string) => {
    console.error('Breakpoint change', newBreakpoint);
    setCurrentBreakPoint(newBreakpoint as Breakpoint);
  };

  return (
    <Context.Provider value={{ showMenu: false, localPreferences: context.localPreferences }}>
      <div className={'w-100 '} ref={ref} key={'div-cont'}>
        {internalLayouts && currentBreakpoint && activeWidgets && (
          <ResponsiveReactGridLayout
            key={'grid'}
            draggableHandle={'.drag-handle'}
            onLayoutChange={onLayoutChange}
            onBreakpointChange={breakpointChange}
            onDrag={_preventTextSelect}
            onResize={_preventTextSelect}
            onResizeStop={_preventTextSelect}
            onDragStart={_preventTextSelect}
            onDragStop={_preventTextSelect}
            className="layout"
            isDraggable={editMode}
            isDroppable={editMode}
            isResizable={editMode}
            cols={cols}
            rowHeight={100}
            layouts={internalLayouts}
            margin={[10, 10]}
            useCSSTransforms={true}
            breakpoints={breakpoints}
            resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
            containerPadding={[5, 5]}
          >
            {widgetPanels}
          </ResponsiveReactGridLayout>
        )}

        {activeWidgets?.activeWidgets.length == 0 && (
          <div className={'d-flex w-100 flex-column justify-content-around align-items-center p-5'}>
            <div style={{ width: '10em' }}>
              <EmptyStateIndicator
                text={
                  <>
                    No Items
                    <div>
                      <DashboardAddItemControl
                        onAddWidget={w =>
                          dispatch({
                            type: 'addWidget',
                            id: w,
                          })
                        }
                        activeWidgets={activeWidgets.activeWidgets}
                      />
                    </div>
                  </>
                }
              />
            </div>
          </div>
        )}
      </div>
    </Context.Provider>
  );
}
