import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { MNIDashboard } from '@/model/preferences/myMNIPreferences';

export default function DashboardViewControls({
  onDelete,
  onEdit,
  selectedDashboard,
  onDashboardSelect,
  dashboards,
  onDashboardCreate,
}: {
  onDelete: () => void;
  onEdit: () => void;
  onDashboardCreate: () => void;
  onDashboardSelect: (d: MNIDashboard) => void;
  selectedDashboard?: MNIDashboard;
  dashboards: MNIDashboard[];
}) {
  return (
    <>
      <ButtonGroup>
        <DropdownButton
          as={ButtonGroup}
          className={''}
          size={'sm'}
          variant={'outline-info'}
          style={{ fontFamily: 'Inter' }}
          title={selectedDashboard?.name || '-none-'}
        >
          {dashboards.length > 1 ? (
            dashboards
              .filter(value => value.name !== selectedDashboard?.name)
              .map(value => (
                <Dropdown.Item onClick={event => onDashboardSelect(value)} key={value.id}>
                  {value.name}
                </Dropdown.Item>
              ))
          ) : (
            <Dropdown.Item disabled={true}>No dashboards</Dropdown.Item>
          )}
          {dashboards.length < 5 && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item onClick={onDashboardCreate}>Create dashboard</Dropdown.Item>
            </>
          )}
          <>
            <Dropdown.Divider />
            <Dropdown.Item onClick={onDelete} className={'text-danger'}>
              Delete current dashboard
            </Dropdown.Item>
          </>
        </DropdownButton>

        <button
          style={{ fontFamily: 'Inter' }}
          className={'btn btn-info me-2 btn-sm'}
          onClick={onEdit}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
      </ButtonGroup>
    </>
  );
}
