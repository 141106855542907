import { useEffect, useRef, useState } from 'react';

import { Article } from '@/model/article';
import DateFormat from '@/components/shared/date-format';
import UpgradePanel from '@/components/shared/upgrade-panel';
import KeywordBadge from '@/components/shared/keyword-badge';
import TaggedHeadline from '@/components/shared/tagged-headline';

import styles from '@/components/articles/article-view.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { opinionsApi } from '@/api';
import { OpinionType } from '@/api/opinions-api';
import BookmarkButton from '@/components/bookmarks/bookmark-button';
import PinButton from '@/components/pins/pin-button';
import ArticleBodyDisplay from '@/components/articles/article-body-display';

export default function ArticleView({
  article,
  onClose,
  showBookmark = true,
  showBack = true,
  showPin = true,
}: {
  article: Article;
  onClose: Function;
  showBookmark?: boolean;
  showBack?: boolean;
  showPin?: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref && article) {
      const anchors = ref.current?.getElementsByTagName<'a'>('a');

      if (anchors) {
        for (const a of anchors) {
          a.setAttribute('target', '_blank');
        }
      }
    }
  }, [ref, article]);

  return (
    <div className="container-element h-100">
      <div className="container-responsive-text h-100 overflow-auto">
        <div className={'sticky-top bg-body'}>
          <div className={'d-flex '}>
            <div className={'me-auto'}>
              <h4>
                <TaggedHeadline headline={article.headline} />
              </h4>
            </div>

            <div className={'text-end'}>
              <span className={'pt-1'}>
                {showPin && (
                  <span className={'me-1'}>
                    <PinButton article={article} onClick={() => onClose()} />
                  </span>
                )}
                {showBookmark && (
                  <span className={'me-1'}>
                    <BookmarkButton article={article} />
                  </span>
                )}
              </span>

              {showBack && (
                <button
                  onClick={() => onClose()}
                  style={{ fontFamily: 'Inter' }}
                  className={'btn btn-sm btn-outline-secondary text-secondary btn-menu'}
                >
                  Back
                </button>
              )}
            </div>
          </div>
          <div className={'d-flex justify-content-between'}>
            By {article.byline}
            <div className={'text-muted'}>
              <DateFormat format={'MMM D, YYYY, h:mm a'} date={article.versioncreated} />
            </div>
          </div>
          <div className={'text-text-dark small mt-1'}>
            <div dangerouslySetInnerHTML={{ __html: article.summary }} />
          </div>
          <div>
            {article.keywords.map(s => (
              <span className={'small'}>
                <KeywordBadge keyword={s} />{' '}
              </span>
            ))}
          </div>
          <hr />
        </div>

        <div className={'text-text-dark '}>
          <ArticleBodyDisplay xhtml={article.body_xhtml} />
        </div>

        {article.representationtype === 'partial' && (
          <div className={'text-center'}>
            <div className={'col-container-sm-8 offset-container-sm-2'}>
              <div className={'card bg-black shadow-lg border border-primary'}>
                <UpgradePanel
                  heading={'Contact us to read the full article'}
                  leadMessage={'I would like to read the article: ' + article.headline}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
