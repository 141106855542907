import { Dropdown, DropdownButton } from 'react-bootstrap';
import useWidgetFactory from '@/hooks/use-widget-factory';
import { NewsWidget } from '@/widgets/news-widget';
import { WidgetUpgradeBadge } from '@/widgets/widget-permissions/widget-upgrade-badge';
import { WidgetMenuEntry } from '@/widgets/news-widget-factory';

export default function DashboardAddItemControl({
  onAddWidget,
  activeWidgets,
}: {
  onAddWidget: (w: string) => void;
  activeWidgets: NewsWidget[];
}) {
  const widgetFactory = useWidgetFactory();

  return (
    <DropdownButton variant={'outline-secondary'} title={'Add Item'} size={'sm'}>
      {widgetFactory
        .getStaticWidgetList()
        .filter(value => !activeWidgets.find(w => value.id === w.id))
        .map(value => (
          <Dropdown.Item onClick={() => onAddWidget(value.id)} key={value.id + '-widget'}>
            {value.headerPanel as JSX.Element}{' '}
            <sup>
              <WidgetUpgradeBadge widget={value} />
            </sup>
          </Dropdown.Item>
        ))}

      <Dropdown.Divider />

      {widgetFactory
        .getDynamicWidgetFactories()
        .filter(value => value.getListableUrls().length > 0)
        .map((value, index) => {
          function getDropdownButton(
            name: string | JSX.Element,
            items: WidgetMenuEntry[],
            i: string,
          ) {
            return (
              <DropdownButton
                key={'fact-' + i}
                title={<>{name}</>}
                drop={'start'}
                className={'dropdown-item'}
              >
                {items.map((item, windex) => {
                  if (item.url !== undefined) {
                    return (
                      <Dropdown.Item
                        key={item.url + '-d-widget'}
                        onClick={() => onAddWidget(item.url || '')}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  } else {
                    return getDropdownButton(item.name, item.children, `${index}-${windex}`);
                  }
                })}
              </DropdownButton>
            );
          }

          return getDropdownButton(value.headerPanel, value.getListableUrls(), `${index}`);
        })}
    </DropdownButton>
  );
}
